<template>
  <div class='h-full flex flex-col lg:flex-row justify-between items-stretch'>
    <img :src='selectedImage.sourceUrl' class='p-4 flex-grow-0 flex-shrink-0 w-full lg:w-2/3 h-1/2 lg:h-full object-contain bg-gray-900' />
    <div class='flex-shrink-0 flex-grow-0 w-full lg:w-1/3 bg-white flex flex-col justify-between h-1/2 lg:h-auto'>
      <div class='flex-shrink-0 py-6 px-8 border-b font-semibold text-sm'>
        {{interactionData.uploadedParticipantName}}
      </div>
      <div class='flex-grow p-4 lg:p-8 overflow-y-auto text-sm'>
        <p class='text-gray-500 mb-4'>
          <span class='text-gray-900 font-semibold leading-5'>{{interactionData.uploadedParticipantName}}</span>
          {{interactionData.uploadedComment}}         
        </p>
        <p v-for='(comment, index) in interactionData.comments'
          :key='`comment-for-image-${index}`'
          class='text-gray-500 mb-4'>
          <span class='text-gray-900 font-semibold leading-5'>{{comment.participantName}}</span>
          {{comment.comment}}
        </p>
      </div>
      <div class='flex-shrink-0'>
        <div class='border-t border-b px-8'>
          <div class='py-4 flex flex-row justify-start items-start gap-x-4'>
            <heart-icon 
              :class='whichHeartIcon'
              class='cursor-pointer'
              @click.stop='toggleMyLike' />
            <message-circle-icon :class='whichCommentIcon' />
            <trash-icon 
              v-if='canDeletePost'
              class='text-gray-600 hover:text-gray-900 cursor-pointer hover:bg-gray-200 rounded-md'
              @click.stop='confirmDelete' />
          </div>
          <h5 class='font-semibold text-gray-900 mb-2 pb-4'>
            {{likeCount}} likes
          </h5>
        </div>
        <div class='relative'>
          <input
            v-model='newComment'
            type='text'
            class='w-full px-8 py-4'
            placeholder='Add a Comment...'
            v-on:keyup.enter='postComment' />
          <button
            class='post-button z-10 absolute right-2 top-2 text-sm uppercase p-2'
            :disabled='disablePostButton'
            :style='themeTextColorStyle'
            @click='postComment'>
            Post
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { HeartIcon, MessageCircleIcon, TrashIcon } from 'vue-tabler-icons'

export default {
  name: 'KoreanTourParticipantImagesModal',
  components: {
    MessageCircleIcon,
    HeartIcon,
    TrashIcon,
  },
  props: [
    'selectedImage',
  ],
  data () {
    return {
      newComment: '',
    }
  },
  computed: {
    ...mapState('users', [
      'profile',
    ]),
    ...mapGetters('users', [
      'isAdmin',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    themeTextColorStyle () {
      let color = (this.eventMainThemeColor) ? this.eventMainThemeColor : '#333' 
      return `color: ${color};`
    },
    disablePostButton () {
      return !this.newComment
    },
    interactionData () {
      return this.selectedImage.socialMediaStyleData
    },
    likeCount () {
      return this.interactionData.likedBy.length
    },
    meLikedThis () {
      return this.interactionData.likedBy.includes(this.profile.id)
    },
    whichHeartIcon () {
      return (this.meLikedThis) ? 'text-red-500 fill-current' : ''
    },
    whichCommentIcon () {
      return (this.interactionData.meCommented) ? '' : ''
    },
    changeToLikeStatus () {
      return this.meLikedThis ? 'remove' : 'add'
    },
    canDeletePost () {
      return this.isAdmin || this.profile.id === this.selectedImage.socialMediaStyleData.uploadedParticipantProfileId
    },
  },
  methods: {
    ...mapActions('users', [
      'getProfile',
    ]),
    ...mapActions('contents', [
      'updateSocialMediaStyleContentLikes',
      'updateSocialMediaStyleContentComments',
      'deleteSocailMediaStyleContent',
    ]),
    postComment () {
      let comment = {
        participantName: `${this.profile.firstName} ${this.profile.lastName}`,
        comment: this.newComment
      }
      this.updateSocialMediaStyleContentComments({
        contentId: this.selectedImage.id,
        comment: comment
      }).then(resp => {
        this.selectedImage.socialMediaStyleData.comments.push(comment)
        this.$emit('update:image-content', resp)
        this.newComment = ''
      })
    },
    toggleMyLike () {
      this.updateSocialMediaStyleContentLikes({
        contentId: this.selectedImage.id,
        profileId: this.profile.id,
        likeStatus: this.changeToLikeStatus
      }).then(resp => {
        this.selectedImage.socialMediaStyleData.likedBy = resp.socialMediaStyleData.likedBy
        this.$emit('update:image-content', resp)
      })
    },
    confirmDelete() {
      this.$confirm('Are you sure you want to delete this post?', {
        confirmButtonText: 'Yes, Delete Post',
        cancelButtonText: 'No, Keep My Post',
        type: 'warning',
      }).then(() => {
        this.deletePost()
      }).catch(() => {})
    },
    deletePost() {
      this.deleteSocailMediaStyleContent(this.imageContent.id).then(resp => {
        console.log('delete post response', resp)
        this.$modal.hide('image-modal')
        this.$emit('deletedImage', this.imageContent.id)
      })
    },
  },
}
</script>

<style lang='scss' scoped>
  .post-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;

  }
</style>
