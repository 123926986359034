<template>
  <div>
    <div class='p-8 h-full overflow-y-auto'>
      <h1
        class='mb-6 text-2xl font-semibold'>Upload New Photo</h1>
      <div class='mb-6'>
        <label class='style-default'>Photo Description</label>
        <input
          type='text'
          v-model='uploadComment'
          class='style-default w-full' />
      </div>
      <div class='mb-6'>
        <label class='style-default mb-2'>
          Upload image
        </label>
        <img v-if='sourceUrl'
          class='h-28 w-40 object-contain mb-6'
          :src='sourceUrl'/>
        <input
          class='block'
          type='file'
          ref='contentImage'
          @change='updateContentImageFile'
          accept='image/*'/>
      </div>
    </div>
    <div class='absolute bottom-0 left-0 right-0 py-4 px-8 bg-gray-100'>
      <button
        class='flex flex-row items-center justify-center gap-x-2 text-normal text-white uppercase rounded shadow-md py-4 px-24'
        style='background-color: var(--darkColor);'
        :disabled='disabledUploadButton'
        :class='disabledUploadButtonClass'
        @click='uploadImage'>
        <cloud-upload-icon class='h-5' />
        Upload
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { CloudUploadIcon } from 'vue-tabler-icons'

export default {
  name: 'SocialMediaStyleImageUpload',
  components: {
    CloudUploadIcon,
  },
  props: [
  ],
  data () {
    return {
      sourceUrl: '',
      uploadComment: '',
      imageContent: null,
    }
  },
  computed: {
    ...mapState('users', [
      'profile',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'showingEventName',
    ]),
    ...mapFields('contents', [
      'uploadFormData',
    ]),
    currentProfileName () {
      return `${this.profile.firstName} ${this.profile.lastName}`
    },
    newContentParams () {
      return {
        contentFormat: 'image',
        contentType: 'social_media_style',
        isActive: true,
        accessAuthorities: [ 'free' ],
        eventId: this.showingEventId,
        permissions: [ this.showingEventName ],
        sourceUrl: this.sourceUrl,
        socialMediaStyleData: { 
          comments: [],
          likedBy: [],
          uploadedComment: this.uploadComment, 
          uploadedParticipantProfileId: this.profile.id,
          uploadedParticipantName: this.currentProfileName
        }
      }
    },
    disabledUploadButton () {
      return !this.sourceUrl
    },
    disabledUploadButtonClass () {
      return this.disabledUploadButton ? 'opacity-30' : 'opacity-100'
    },
  },
  methods: {
    ...mapActions('contents', [
      'uploadParticipantImageContent',
    ]),
    ...mapActions([
      'showFullpageLoadingIndicator',
      'hideFullpageLoadingIndicator',
    ]),
    resetInputFields () {
      this.sourceUrl = ''
      this.uploadComment = ''
      this.imageContent = null
    },
    updateContentImageFile () {
      const files = this.$refs.contentImage.files
      this.uploadFormData.append('content_image', files[0])
      this.$nextTick(() => {
        if (files[0]) {
          this.sourceUrl = URL.createObjectURL(files[0])
        }
      })
    },
    uploadImage () {
      this.showFullpageLoadingIndicator()
      this.uploadParticipantImageContent(this.newContentParams).then(resp => {
        this.$emit('uploaded-new-image', resp)
        this.$modal.hide('upload-new-image-modal')
        this.hideFullpageLoadingIndicator()
      })
    },
  },
  beforeDestroy () {
    this.resetInputFields()
  }
}
</script>
