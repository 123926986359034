<template>
  <div class='relative pb-24'>
    <content-background-cover v-if='includeBackgroundCover'
      class='z-0'
      :top-offset='0'
      :custom-css='backgroundCss' />
    <h1 class='text-center text-xl font-semibold my-8 uppercase'>Selfie Event</h1>
    <img v-if='bannerImage'
      class='w-full'
      :src='bannerImage' />
    <div class='text-center'>
      <h1  
        id='social-media-style-images'
        class='text-3xl my-8' 
        style='color:var(--eventMainColor);'>
        {{sectionTitle}}
      </h1>
      <button
        class='flex flex-row items-center justify-center gap-x-2 text-normal text-white uppercase rounded shadow-md py-4 px-24 mx-auto my-16'
        style='background-color: var(--darkColor);'
        @click='openUploadImage'>
        <cloud-upload-icon class='h-5' />
        Upload
      </button>
    </div>
    <div class='flex flex-row flex-wrap items-center justify-center gap-x-4 gap-y-12'>
      <social-media-style-images-card v-for='imageContent in participantImageContent'
        :key='`participant-uploaded-images-${imageContent.id}`'
        v-bind:image-content.sync='imageContent'
        :my-profile-id='profile.id'
        class='w-80'
        @click.native='selectThisImage(imageContent)' 
        @deletedImage='deletedImage' />
    </div>

    <modal
      name='image-modal'
      :adaptive='true'
      classes='social-media-style-image-modal relative'
      @closed='resetSelectedImage'>
      <button class='z-30 absolute top-3 right-3' @click="$modal.hide('image-modal')">
        <x-icon class='text-white lg:text-black' />
      </button>
      <social-media-style-images-modal v-if='selectedImage'
        :image-id='selectedImage'
        :selected-image.sync='selectedImage'
        :my-profile-id='profile.id' />
    </modal>
    <modal
      name='upload-new-image-modal'
      :adaptive='true'
      classes ='social-media-style-image-modal relative'>
        <button class='z-30 absolute top-3 right-3' @click="$modal.hide('upload-new-image-modal')">
          <x-icon class='text-black' />
        </button>
        <social-media-style-image-upload 
          @uploaded-new-image='addToImages' />
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { CloudUploadIcon, XIcon }        from 'vue-tabler-icons'
import ContentBackgroundCover           from '@/components/ContentBackgroundCover.vue'
import SocialMediaStyleImagesCard  from '@/components/social-media-style/SocialMediaStyleImagesCard.vue'
import SocialMediaStyleImagesModal from '@/components/social-media-style/SocialMediaStyleImagesModal.vue'
import SocialMediaStyleImageUpload from '@/components/social-media-style/SocialMediaStyleImageUpload.vue'

export default {
  name: 'SocialMediaStyleImages',
  components: {
    ContentBackgroundCover,
    SocialMediaStyleImagesCard,
    SocialMediaStyleImagesModal,
    SocialMediaStyleImageUpload,
    CloudUploadIcon,
    XIcon,
  },
  props: {
    sectionTitle: {
      default: '',
      type: String
    },
    includeBackgroundCover: {
      default: true,
      type: Boolean
    },
    bannerImage: {
      default: '',
      type: String
    },
  },
  data () {
    return {
      selectedImage: null,
      participantImageContent: [],
      newImageUpload: null,
    }
  },
  computed: {
    ...mapState('users', [
      'profile',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
      'colorsInRange',
    ]),
    backgroundCss () {
      return `background: linear-gradient(151.68deg, ${this.colorsInRange[0]} 10.86%, ${this.colorsInRange[1]} 172.48%);`
    },
  },
  methods: {
    ...mapActions('users', [
      'getProfile',
    ]),
    ...mapActions('contents', [
      'getSocialMediaStyleContents',
    ]),
    resetSelectedImage () {
      this.selectedImage = null
    },
    selectThisImage (image) {
      this.selectedImage = image
      this.$modal.show('image-modal')
    },
    addToImages (newImage) {
      this.participantImageContent.unshift(newImage)
    },
    openUploadImage () {
      this.$modal.show('upload-new-image-modal')
    },
    deletedImage(imageId) {
      this.participantImageContent = this.participantImageContent.filter(image => image.id !== imageId)
      this.$message({
        type: 'success',
        message: 'Image has been successfully deleted.'
      })
    },
  },
  mounted () {
    this.getProfile()
    this.getSocialMediaStyleContents().then(resp => {
      this.participantImageContent = resp
    })
  },
}
</script>

<style lang='scss'>
.social-media-style-image-modal {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

@media (min-width: 1024px) { 
  .social-media-style-image-modal {
    left: 15% !important;
    top: calc(50% - 400px) !important;
    height: 800px !important;
    width: 70% !important;
  }
}

</style>
