<template>
      <div class='uploaded-card rounded cursor-pointer hover:shadow-lg relative'>
        <div 
          class='uploaded-image h-60 w-80'
          style='background-color: #F6F3F5;'>
          <img :src='imageContent.sourceUrl' class='w-full h-full object-cover' />
        </div>
        <div class='bg-white p-4 h-56 text-sm'>
          <div class='flex flex-row justify-start items-start gap-x-4 mb-6'>
            <heart-icon 
              :class='whichHeartIcon'
              class='cursor-pointer'
              @click.stop='toggleMyLike' />
            <message-circle-icon :class='whichCommentIcon' />
            <trash-icon 
              v-if='canDeletePost'
              class='text-gray-600 hover:text-gray-900 cursor-pointer hover:bg-gray-200 rounded-md'
              @click.stop='confirmDelete' />
          </div>
          <h5 class='font-semibold text-gray-900 mb-2'>
            {{likeCount}} likes
          </h5>
          <p class='text-gray-500 mb-8'>
            <span class='text-gray-900 font-semibold text-sm leading-5'>{{interactionData.uploadedParticipantName}}</span>
            {{interactionData.uploadedComment}}         
          </p>
          <p class='text-gray-600'>View all {{interactionData.comments.length}} Comments</p>
        </div>  
      </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { HeartIcon, MessageCircleIcon, TrashIcon } from 'vue-tabler-icons'

export default {
  name: 'KoreanTourParticipantImagesCard',
  components: {
    MessageCircleIcon,
    HeartIcon,
    TrashIcon,
  },
  props: [
    'imageContent',
    'myProfileId',
  ],
  computed: {
    ...mapGetters('users', [
      'isAdmin',
    ]),
    interactionData () {
      return this.imageContent.socialMediaStyleData
    },
    likeCount () {
      return this.interactionData.likedBy.length
    },
    meLikedThis () {
      return this.interactionData.likedBy.includes(this.myProfileId)
    },
    whichHeartIcon () {
      return (this.meLikedThis) ? 'text-red-500 fill-current' : ''
    },
    whichCommentIcon () {
      return (this.imageContent.meCommented) ? '' : ''
    },
    changeToLikeStatus () {
      return this.meLikedThis ? 'remove' : 'add'
    },
    canDeletePost () {
      return this.isAdmin || this.myProfileId === this.imageContent.socialMediaStyleData.uploadedParticipantProfileId
    },
  },
  methods: {
    ...mapActions('contents', [
      'updateSocialMediaStyleContentLikes',
      'deleteSocailMediaStyleContent',
    ]),
    toggleMyLike () {
      this.updateSocialMediaStyleContentLikes({
        contentId: this.imageContent.id,
        profileId: this.myProfileId,
        likeStatus: this.changeToLikeStatus
      }).then(resp => {
        this.imageContent.socialMediaStyleData.likedBy = resp.socialMediaStyleData.likedBy
        this.$emit('update:image-content', resp)
      })
    },
    confirmDelete() {
      this.$confirm('Are you sure you want to delete this post?', {
        confirmButtonText: 'Yes, Delete Post',
        cancelButtonText: 'No, Keep My Post',
        type: 'warning',
      }).then(() => {
        this.deletePost()
      }).catch(() => {})
    },
    deletePost() {
      this.deleteSocailMediaStyleContent(this.imageContent.id).then(resp => {
        console.log('delete post response', resp)
        this.$emit('deletedImage', this.imageContent.id)
      })
    },
  }
}
</script>

<style lang='scss' scoped>
  .uploaded-image {
    transition: all 0.2s ease-in-out;
    @apply p-4;
  }

  .uploaded-card:hover .uploaded-image {
    @apply p-0;
  }
</style>
